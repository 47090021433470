<template>
  <div id="about"/>
</template>
<script>
import * as THREE from 'three'
// import Inflate from 'three/examples/jsm/libs/inflate.module'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import Stats from 'three/examples/jsm/libs/stats.module'
import dat from 'three/examples/js/dat.gui.min'
import Detector from 'three/examples/js/Detector.js'
// 开启效果
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js'

var renderer, camera, scene, gui, light, stats, controls, meshHelper, meshHelper1, mixer, action, curobj
var clock = new THREE.Clock()
const group = new THREE.Group()
export default {
  data () {
    return {
      composer: null,
      outlinePass: null,
      renderPass: null
    }
  },
  created () {
    document.body.addEventListener('click', this.getIntersects) // 监听窗口鼠标单击事件
    this.draw()
  },
  methods: {
    initRender () {
      renderer = new THREE.WebGLRenderer({ antialias: true })
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(window.innerWidth, window.innerHeight)
      renderer.setClearColor(0xeeeeee)
      renderer.shadowMap.enabled = true
      // 告诉渲染器需要阴影效果
      document.body.appendChild(renderer.domElement)
    },
    initCamera () {
      camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 5000)
      camera.position.set(100, 200, 300)
    },
    initScene () {
      scene = new THREE.Scene()
      scene.background = new THREE.Color(0xa0a0a0)
      scene.fog = new THREE.Fog(0xa0a0a0, 200, 5000)
    },
    // 初始化dat.GUI简化试验流程
    initGui () {
    // 声明一个保存需求修改的相关数据的对象
      gui = {
        animation: true,
        helper: true // 模型辅助线
      }
      var datGui = new dat.GUI()
      // 将设置属性添加到gui当中，gui.add(对象，属性，最小值，最大值）
      datGui.add(gui, 'animation').onChange(function (e) {
        if (e) {
          action.play()
        } else {
          action.stop()
        }
      })

      datGui.add(gui, 'helper').onChange(function (e) {
        meshHelper.visible = e
        meshHelper1.visible = e
      })
    },
    initLight () {
      scene.add(new THREE.AmbientLight(0x444444))

      light = new THREE.DirectionalLight(0xffffff)
      light.position.set(0, 200, 100)

      light.castShadow = true
      light.shadow.camera.top = 180
      light.shadow.camera.bottom = -100
      light.shadow.camera.left = -120
      light.shadow.camera.right = 120

      // 告诉平行光需要开启阴影投射
      light.castShadow = true

      scene.add(light)
    },
    initModel () {
    // 辅助工具
      var helper = new THREE.AxesHelper(50)
      scene.add(helper)

      // 地板
      var mesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(2000, 2000), new THREE.MeshPhongMaterial({ color: 0xffffff, depthWrite: false }))
      mesh.rotation.x = -Math.PI / 2
      mesh.receiveShadow = true
      var texture = new THREE.TextureLoader().load('./static/tree/Leaves0120_35_S.png')
      // 颜色贴图中已经包含了光照信息，所以直接使用不受光照影响的基础网格材质MeshBasicMaterial
      mesh.material.side = THREE.DoubleSide
      mesh.material = new THREE.MeshBasicMaterial({
        map: texture // 设置颜色纹理贴图
      })
      scene.add(mesh)

      // 添加地板割线
      var grid = new THREE.GridHelper(5000, 20, 0x000000, 0x000000)
      grid.material.opacity = 0.2
      grid.material.transparent = true
      scene.add(grid)
      // 加载OBJ模型
      var objloader = new OBJLoader()
      var mtlloader = new MTLLoader()// 材质文件加载器

      mtlloader.load('./static/character/Anime_charcter.mtl', function (materials) {
        // 返回一个包含材质的对象MaterialCreator
        console.log(materials)
        materials.side = THREE.DoubleSide // 双面贴图
        // obj的模型会和MaterialCreator包含的材质对应起来
        objloader.setMaterials(materials)
        objloader.load('./static/character/Anime_charcter.obj', function (obj) {
          console.log(obj)
          obj.scale.set(20, 20, 20) // 放大obj组对象
          obj.traverse((child) => {
            if (child.isMesh) {
              var texture = new THREE.TextureLoader().load('./static/character/anime.png')
              // 颜色贴图中已经包含了光照信息，所以直接使用不受光照影响的基础网格材质MeshBasicMaterial
              child.material = new THREE.MeshBasicMaterial({
                map: texture // 设置颜色纹理贴图
              })
            }
          })
          obj.position.set(50, 0, 0)
          // 添加骨骼辅助
          meshHelper1 = new THREE.SkeletonHelper(obj)
          scene.add(meshHelper1)
          group.add(obj)
          scene.add(obj)// 返回的组对象插入场景中
        })
      })

      // 加载FBX模型
      var loader = new FBXLoader()
      loader.load('./static/Walking.fbx', function (mesh) {
        console.log(mesh)

        // 添加骨骼辅助
        meshHelper = new THREE.SkeletonHelper(mesh)
        scene.add(meshHelper)

        // 设置模型的每个部位都可以投影
        mesh.traverse(function (child) {
          if (child.isMesh) {
            child.castShadow = true
            child.receiveShadow = true
            // 如果贴图出现问题，替换掉
            // child.material.emissive = new THREE.Color('#336699')
            // child.material.emissiveIntensity = 1
            // child.material.emissiveMap = child.material.map
          }
        })

        // AnimationMixer是场景中特定对象的动画播放器。当场景中的多个对象独立动画时，可以为每个对象使用一个AnimationMixer
        mixer = mesh.mixer = new THREE.AnimationMixer(mesh)

        // mixer.clipAction 返回一个可以控制动画的AnimationAction对象  参数需要一个AnimationClip 对象
        // AnimationAction.setDuration 设置一个循环所需要的时间，当前设置了一秒
        // 告诉AnimationAction启动该动作
        action = mixer.clipAction(mesh.animations[0])
        action.timeScale = 1 // 默认1，可以调节播放速度
        // action.loop = THREE.LoopOnce // 不循环播放
        // action.clampWhenFinished = true // 暂停在最后一帧播放的状态
        action.play()
        group.add(mesh)
        scene.add(mesh)
      })
    },
    // 初始化性能插件
    initStats () {
      stats = new Stats()
      document.body.appendChild(stats.dom)
    },
    initControls () {
      controls = new OrbitControls(camera, renderer.domElement)
      // 设置控制器的中心点
      controls.target.set(0, 100, 0)
      // 如果使用animate方法时，将此函数删除
      // controls.addEventListener( 'change', render );
      // 使动画循环使用时阻尼或自转 意思是否有惯性
      controls.enableDamping = true
      // 动态阻尼系数 就是鼠标拖拽旋转灵敏度
      // controls.dampingFactor = 0.25;
      // 是否可以缩放
      controls.enableZoom = true
      // 是否自动旋转
      controls.autoRotate = false
      controls.autoRotateSpeed = 0.5
      // 设置相机距离原点的最远距离
      controls.minDistance = 1
      // 设置相机距离原点的最远距离
      controls.maxDistance = 2000
      // 是否开启右键拖拽
      controls.enablePan = true
    },
    render () {
      var time = clock.getDelta()
      if (mixer) {
        mixer.update(time)
      }
      if (curobj) curobj.position.x += 100
      controls.update()
      if (this.composer) {
        this.composer.render()
      }
    },
    // 高亮显示模型（呼吸灯）
    outlineObj (selectedObjects) {
      // 创建一个EffectComposer（效果组合器）对象，然后在该对象上添加后期处理通道。
      this.composer = new EffectComposer(this.renderer)
      // 新建一个场景通道  为了覆盖到原理来的场景上
      this.renderPass = new RenderPass(this.scene, this.camera)
      this.composer.addPass(this.renderPass)
      // 物体边缘发光通道
      this.outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), this.scene, this.camera, selectedObjects)
      this.outlinePass.selectedObjects = selectedObjects
      this.outlinePass.edgeStrength = 10.0 // 边框的亮度
      this.outlinePass.edgeGlow = 1// 光晕[0,1]
      this.outlinePass.usePatternTexture = false // 是否使用父级的材质
      this.outlinePass.edgeThickness = 1.0 // 边框宽度
      this.outlinePass.downSampleRatio = 1 // 边框弯曲度
      this.outlinePass.pulsePeriod = 5 // 呼吸闪烁的速度
      this.outlinePass.visibleEdgeColor.set(parseInt(0x00ff00)) // 呼吸显示的颜色
      this.outlinePass.hiddenEdgeColor = new THREE.Color(0, 0, 0) // 呼吸消失的颜色
      this.outlinePass.clear = true
      this.composer.addPass(this.outlinePass)
      // 自定义的着色器通道 作为参数
      var effectFXAA = new ShaderPass(FXAAShader)
      effectFXAA.uniforms.resolution.value.set(1 / window.innerWidth, 1 / window.innerHeight)
      effectFXAA.renderToScreen = true
      this.composer.addPass(effectFXAA)
    },
    // 窗口变动触发的函数
    onWindowResize () {
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()
      renderer.setSize(window.innerWidth, window.innerHeight)
    },
    animate () {
    // 更新控制器
      this.render()

      // 更新性能插件
      stats.update()
      // scene.rotation.set(0, 0.01, 0)
      renderer.render(scene, camera)

      requestAnimationFrame(this.animate)
    },
    draw () {
    // 兼容性判断
      if (!Detector.webgl) Detector.addGetWebGLMessage()

      this.initGui()
      this.initRender()
      this.initScene()
      this.initCamera()
      this.initLight()
      this.initModel()
      this.initControls()
      this.initStats()
      // this.addSound()

      this.animate()
      window.onresize = this.onWindowResize
    },
    addSound () {
      var listener = new THREE.AudioListener()
      camera.add(listener)

      // create a global audio source
      var sound = new THREE.Audio(listener)

      // load a sound and set it as the Audio object's buffer
      var audioLoader = new THREE.AudioLoader()
      audioLoader.load('http://www.3j9s.com/dist/mp3/liangzhu.mp3', function (buffer) {
        sound.setBuffer(buffer)
        sound.setLoop(true)
        sound.setVolume(0.5)
        sound.play()
      })
    },
    mlight () {
      /**
 * 光源设置
 */
      // 点光源
      var point = new THREE.PointLight(0xffffff, 0.3)
      point.position.set(400, 200, 300) // 点光源位置
      scene.add(point) // 点光源添加到场景中
      // 环境光
      var ambient = new THREE.AmbientLight(0xffffff, 0.8)
      scene.add(ambient)
      // 方向光1
      var directionalLight = new THREE.DirectionalLight(0xffffff, 0.5)
      directionalLight.position.set(400, 200, 300)
      scene.add(directionalLight)
      // 方向光2
      directionalLight = new THREE.DirectionalLight(0xffffff, 0.5)
      directionalLight.position.set(-400, -200, -300)
      scene.add(directionalLight)
    },
    getIntersects (event) {
      var Sx = event.clientX // 鼠标单击位置横坐标
      var Sy = event.clientY // 鼠标单击位置纵坐标
      // 屏幕坐标转WebGL标准设备坐标
      var x = (Sx / window.innerWidth) * 2 - 1 // WebGL标准设备横坐标
      var y = -(Sy / window.innerHeight) * 2 + 1 // WebGL标准设备纵坐标
      // 创建一个射线投射器`Raycaster`
      var raycaster = new THREE.Raycaster()
      // 通过鼠标单击位置标准设备坐标和相机参数计算射线投射器`Raycaster`的射线属性.ray
      raycaster.setFromCamera(new THREE.Vector2(x, y), camera)
      // 返回.intersectObjects()参数中射线选中的网格模型对象
      // 未选中对象返回空数组[],选中一个数组1个元素，选中两个数组两个元素
      var intersects = raycaster.intersectObjects(scene.children, true)
      console.log('射线投射器返回的对象', intersects)
      // console.log("射线投射器返回的对象 点point", intersects[0].point);
      // console.log("射线投射器返回的对象 几何体",intersects[0].object.geometry.vertices)
      // intersects.length大于0说明，说明选中了模型
      if (intersects.length > 0) {
        // intersects.Object.object.type === 'Mesh'
        curobj = intersects[0].object
        // 选中模型的第一个设置为半透明
        // intersects[0].object.material.transparent = true
        // intersects[0].object.material.opacity = 0.6
      }
    }
  }
}
</script>

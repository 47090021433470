<template>
  <div id="container" >demo</div>
</template>
<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader'
var scene, obj, chobj // 场景与对象必须为全局变量，在data中会出现无法读取的问题。
export default {
  data () {
    return {
      camera: null,
      renderer: null,
      controls: null,
      modelPath: 'static/small_world/small_world.fbx'
    }
  },
  methods: {
    init () {
      const self = this

      const container = document.getElementById('container')
      // const mouse = new THREE.Vector2()
      self.camera = new THREE.PerspectiveCamera(
        45,
        container.clientWidth / container.clientHeight,
        0.01,
        2000
      )
      self.camera.position.set(100, 200, 300)
      scene = new THREE.Scene()
      // 建立地面
      // var mesh = new THREE.Mesh(
      //   new THREE.PlaneBufferGeometry(2000, 2000),
      //   new THREE.MeshPhongMaterial({ color: 0x999999, depthWrite: false })
      // )
      // mesh.rotation.x = -Math.PI / 2
      // mesh.receiveShadow = true
      // scene.add(mesh)
      // // 绘制栅格
      // var grid = new THREE.GridHelper(2000, 20, 0x000000, 0x000000)
      // grid.material.opacity = 0.2
      // grid.material.transparent = true
      // scene.add(grid)
      // 设置画布
      self.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
        precision: 'highp'
      })
      self.renderer.setSize(container.clientWidth, container.clientHeight)
      self.renderer.shadowMap.enabled = true
      container.appendChild(self.renderer.domElement)
      // 设置控制装置
      self.controls = new OrbitControls(self.camera, self.renderer.domElement)
      self.controls.target.set(0, 100, 0)
      self.controls.update()
      // 加入日光系统
      var light = new THREE.HemisphereLight(0xffffff, 0x444444)
      light.position.set(0, 200, 0)
      scene.add(light)
      // 加入面光
      light = new THREE.DirectionalLight(0x336699)
      light.position.set(0, 500, 500)
      light.castShadow = true
      light.shadow.camera.top = 180
      light.shadow.camera.bottom = -100
      light.shadow.camera.left = -120
      light.shadow.camera.right = 120
      // scene.add(light)
      const fbxLoader = new FBXLoader()
      fbxLoader.load(self.modelPath, function (fbx) {
        fbx.traverse((child) => {
          chobj = child
          if (child.isMesh) {
            // 此处更新fbx 模型的贴图信息 ，需要在引入GTALoader.js
            child.castShadow = true
            child.receiveShadow = true
            const tgaloader = new TGALoader()
            console.log(child.name)
            if (child.name === 'Face_001') {
              tgaloader.load('name对应的贴图文件', (texture) => {
                // 模型使用新的贴图纹理
                texture.needsUpdate = true
                child.material.map = texture
              })
            }

            //= =============替换多个贴图需要进行多个判断 ，有点繁琐 ！ fbxloader.js源码 不支持tga  ，也没有在源码上更改 ，只能这样子先处理了 ！
          }
        })
        obj = fbx
        scene.add(fbx)
      })
    },
    animate () {
      requestAnimationFrame(this.animate)
      this.renderer.clear()
      obj.rotation.y += 0.01
      chobj.rotation.z += 0.1
      this.renderer.render(scene, this.camera)
      this.renderer.clearDepth()
    }
  },
  mounted () {
    this.init()
    this.animate()
  }
}
</script>
<style scoped>
#container {
  height: 100%;
  width: 100%;
}
</style>
